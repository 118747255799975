import React from 'react'
import Layout from '../components/layout'
import NavigationInterior from '../components/navigation-interior'

const NotFoundPage = () => (
  <Layout>
    	<h1>NOT FOUND</h1>
    	<NavigationInterior></NavigationInterior>
  </Layout>
);

export default NotFoundPage